import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  BUSINESS_BOOKER,
  FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS,
  PI,
  PROMO_CODE_COOKIE,
  SESSION_STORAGE_PROMO_COOKIE_SET,
} from '@whitbread-eos/api';
import { ErrorBoundary, Fonts, ScriptsEmbed } from '@whitbread-eos/atoms';
import { CONSENT_COOKIE, CookiePoliciesModalContainer } from '@whitbread-eos/molecules';
import {
  analytics,
  AnalyticsProvider,
  AppDataProvider,
  FeatureToggleContextProvider,
  getCookie,
  setCookieWithDefaultDomain,
  getSecureTwoURL,
  setAnalyticsPageNameAndType,
  useCustomLocale,
  useFeatureSwitch,
  UserContextProvider,
} from '@whitbread-eos/utils';
import format from 'date-fns/format';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { DefaultLayout } from '~components';
import I18NLabels from '~components/common/I18NLabels';

import nextI18NextConfig from '../../next-i18next.config';
import { theme } from '../../theme';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type Props = AppProps & {
  Component: NextPageWithLayout;
};

type Config = {
  appKey: string | undefined;
  spa: { spa2: boolean };
  adrumExtUrlHttp: string;
  adrumExtUrlHttps: string;
  beaconUrlHttp: string;
  beaconUrlHttps: string;
  xd: { enable: boolean };
  resTiming: { bufSize: number; clearResTimingOnBeaconSend: boolean };
  maxUrlLength: number;
};
const extendedTheme = extendTheme(theme);
global.WB = global.WB ?? { cache: {} };

function App({ Component, pageProps }: Props) {
  const isWindowDefined = typeof window !== 'undefined';
  const { publicRuntimeConfig = {} } = getConfig() || {};

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false, // don't automatically refetch data on window (re)-focus
            refetchInterval: false, // don't automatically refetch data after a duration
            staleTime: 1000 * 60 * 5, // after what time is data (including pre-fetched) considered stale
          },
        },
      })
  );
  const { pathname } = useRouter();
  const { language } = useCustomLocale();
  const [isCookieConsentModalOpen, setIsCookieConsentModalOpen] = useState(true);
  const consentCookie = getCookie(CONSENT_COOKIE);
  const closeCookieConsentModal = () => {
    setIsCookieConsentModalOpen(false);
  };
  const getBrandByPath = () => {
    return !pathname.includes(BUSINESS_BOOKER) ? PI : BUSINESS_BOOKER.replace('-', '');
  };
  const getLayout =
    Component.getLayout ??
    ((page: any) => (
      <DefaultLayout>
        <ErrorBoundary>{page}</ErrorBoundary>
      </DefaultLayout>
    ));
  const currentTime = format(new Date(), 'HH:mm');
  const currencyCode = language === 'en' ? 'gbp' : 'eur';

  const isScriptEmbedAppDynamicsEnabled = useFeatureSwitch({
    featureSwitchKey: FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS,
    fallbackValue: false,
  });

  useEffect(() => {
    if (isScriptEmbedAppDynamicsEnabled) {
      (window as any)['adrum-start-time'] = new Date().getTime();
      (function (config: Config) {
        config.appKey = publicRuntimeConfig.PI_APPD_BRUM_API_KEY;
        config.spa = { spa2: true };
        config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
        config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
        config.beaconUrlHttp = 'http://fra-col.eum-appdynamics.com';
        config.beaconUrlHttps = 'https://fra-col.eum-appdynamics.com';
        config.xd = { enable: true };
        config.resTiming = { bufSize: 200, clearResTimingOnBeaconSend: true };
        config.maxUrlLength = 512;
      })((window as any)['adrum-config'] || ((window as any)['adrum-config'] = {}));
    }
    if (!sessionStorage.getItem(SESSION_STORAGE_PROMO_COOKIE_SET)) {
      setCookieWithDefaultDomain(PROMO_CODE_COOKIE, null, -1);
    }
  }, []);

  useEffect(() => {
    analytics.update({
      language,
    });
  }, [language]);

  useEffect(() => {
    setAnalyticsPageNameAndType(pathname);
  }, [pathname]);

  useEffect(() => {
    analytics.update({
      currentTime: currentTime,
      currencyCode: currencyCode,
    });
  }, [currentTime, currencyCode]);

  useEffect(() => {
    analytics.update({
      pageURL: window.location.href,
    });
  }, [isWindowDefined && window?.location?.href]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <AppDataProvider>
      <iframe
        src={`${getSecureTwoURL()}/gb/en/common/login.html`}
        style={{ display: 'none' }}
        id="authIframe"
        title="authIframe"
      ></iframe>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <FeatureToggleContextProvider>
            <UserContextProvider>
              <AnalyticsProvider queryClient={queryClient}>
                <ChakraProvider theme={extendedTheme}>
                  <Fonts />

                  {getLayout(<Component {...pageProps} />)}
                  {!consentCookie && (
                    <CookiePoliciesModalContainer
                      onClose={closeCookieConsentModal}
                      isOpen={isCookieConsentModalOpen}
                      brand={getBrandByPath()}
                    />
                  )}
                </ChakraProvider>
              </AnalyticsProvider>
            </UserContextProvider>
          </FeatureToggleContextProvider>
        </HydrationBoundary>

        {process.env.NODE_ENV === 'development' && pathname !== '/graphql' && (
          <>
            <ReactQueryDevtools />
            <I18NLabels />
          </>
        )}
      </QueryClientProvider>

      <ScriptsEmbed isScriptEmbedAppDynamicsEnabled={isScriptEmbedAppDynamicsEnabled} />
    </AppDataProvider>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
